import { BoxStyleProps } from "@twilio-paste/core/box";
import { TextStyleProps } from "@twilio-paste/core/text";

export const formStyles: BoxStyleProps = {
    padding: "space40",
    paddingTop: "space80",
    overflow: "auto"
};

export const introStyles: TextStyleProps = {
    marginBottom: "space70"
};

export const fieldStyles: BoxStyleProps = {
    marginBottom: "space70"
};

export const fieldStylesDnone: BoxStyleProps = {
    display: "none"
};
