import { BoxStyleProps } from "@twilio-paste/core/box";

export const outerContainerStyles: BoxStyleProps = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
};

export const innerContainerStyles: BoxStyleProps = {
    display: "flex",
    flexDirection: "column",
    width: "320px",
    height: "85vh",
    marginBottom: "space50",
    borderRadius: "borderRadius30",
    backgroundColor: "colorBackgroundBody"
};
